<template>
  <base-text>
    <render-content
      v-if="!isLoopPosReturnMethod"
      data-testid="handlingFeeCallout"
      :data="dataPropsAndText.data"
    >
      {{ dataPropsAndText.text }}
    </render-content>
  </base-text>
</template>

<script setup>
import { computed } from 'vue';
import {
  BaseText
} from '@loophq/design-system';
import { useStore } from 'vuex';
import { formatCurrency } from '@/js/helpers/formatCurrency';

const store = useStore();

const props = defineProps({
  handlingFee: {
    type: Number,
    required: true
  },
  policyHandlingFee: {
    type: Number,
    required: false
  },
  perProductHandlingFee: {
    type: Number,
    required: false
  },
  isLoopPosReturnMethod: {
    type: Boolean,
    required: true,
  }
});

const hasPolicyHandlingFee = computed(() => {
  return props.policyHandlingFee && props.policyHandlingFee !== 0;
});
const hasPerProductHandlingFee = computed(() => {
  return props.perProductHandlingFee && props.perProductHandlingFee !== 0;
});

const displayCurrency = computed(() => {
  return store.getters['currencies/displayCurrency'];
});

const exchangeRate = computed(() => {
  return store.getters['currencies/exchangeRate'];
});

const formatAmount = (amount) => {
  const amountForDisplay = amount * exchangeRate.value;
  return formatCurrency(amountForDisplay, displayCurrency.value);
};

const dataPropsAndText = computed(() => {
  let text;
  let data;

  if (hasPolicyHandlingFee.value && hasPerProductHandlingFee.value) {
    text = store.getters['content'].moduleCreditSelector.handlingFeeCalloutPerProductPolicyAndProduct;
    data = {
      policyHandlingFee: formatAmount(props.policyHandlingFee),
      perProductHandlingFee: formatAmount(props.perProductHandlingFee)
    };
  } else if (!hasPolicyHandlingFee.value && hasPerProductHandlingFee.value) {
    text = store.getters['content'].moduleCreditSelector.handlingFeeCalloutPerProductProductOnly;
    data = {
      perProductHandlingFee: formatAmount(props.perProductHandlingFee)
    };

  } else {
    text = store.getters['content'].moduleCreditSelector.handlingFeeCallout;
    data = {
      handlingFee: formatAmount(props.handlingFee)
    };
  }
  return {
    text,
    data
  };
});
</script>
