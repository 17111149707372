import { returnCreditTypes, selectedReturnMethod } from '@/js/constants/returns';

export const resolveOrReject = (resolve, reject, item) => {
  if (item?.failed) {
    return reject(item);
  } else {
    return resolve(item);
  }
};

export const  returnMethod = (state) => {
  switch (state.creditType) {
    case returnCreditTypes.REFUND:
      return selectedReturnMethod.ORIGINAL_PAYMENT_METHOD;
    case returnCreditTypes.GIFT:
      return state.storeCreditType === selectedReturnMethod.STORE_CREDIT;
    default:
      return null;
  }
};

export const isSplitReturn = (setting, order, lineItems) => {
  if (!setting) {
    return false;
  }

  if (lineItems?.length < 2) {
    return false;
  }

  if (!order.eligibility.refund) {
    return false;
  }

  if (lineItems.some(li => li.returnType === 'exchange')) {
    return false;
  }

  if (order.cart?.length) {
    return false;
  }

  const allQualifyForRefund = lineItems.every(li => li.allowed.refund);
  if (allQualifyForRefund) {
    return false;
  }

  const storeCreditOnly = lineItems.filter(li => li.allowed.gift && !li.allowed.refund);
  const hasRefund = lineItems.some(li => li.allowed.refund);
  if (storeCreditOnly.length && hasRefund) {
    return true;
  }

  return false;
};
