<template>
  <div class="credit-selector">
    <header class="credit-selector__header">
      <base-text
        class="credit-selector__heading"
        tag="h2"
        type="heading"
      >
        <render-content>
          {{ $content.moduleCreditSelector.heading }}
        </render-content>
      </base-text>
    </header>
    <radio-input
      v-if="availableOptions.gift"
      v-model="selected"
      class="credit-selector__input"
      data-testid="store-credit"
      :value="returnCreditTypes.GIFT"
      @focus="updateFocus"
      @focusout="updateFocus"
      @blur="updateFocus"
    >
      <base-card
        ref="storeCredit"
        class="credit-selector__card"
        tag="span"
        :selected="selected === returnCreditTypes.GIFT"
      >
        <span class="credit-selector__card-content">
          <base-icon
            class="credit-selector__card-icon"
            name="gift"
          />
          <span class="credit-selector__label">
            <base-text
              class="credit-selector__heading"
              type="heading"
            >
              <render-content>
                {{ $content.moduleCreditSelector.storeCreditHeading }}
              </render-content>
            </base-text>
            <credit-selector-callout
              v-if="hasStoreCreditFee && !shouldHideFeesInCards"
              :loading="feesLoading"
            >
              <render-content
                v-if="shouldShowCreditFee"
                :data="{ handlingFee: formatAmount(creditFee.amount) }"
              >
                {{ $content.moduleCreditSelector.handlingFeeCallout }}
              </render-content>
            </credit-selector-callout>
            <credit-selector-callout v-else-if="hasStoreCreditBonus">
              <render-content :data="{ bonusCredit: formatAmount(bonusCredit) }">
                {{ $content.moduleCreditSelector.bonusCreditCallout }}
              </render-content>
            </credit-selector-callout>
            <base-text
              class="credit-selector__body"
              type="body-2"
            >
              <render-content>
                {{ $content.moduleCreditSelector.storeCreditCopy }}
              </render-content>
            </base-text>
          </span>
        </span>
      </base-card>
    </radio-input>
    <radio-input
      v-if="availableOptions.refund"
      v-model="selected"
      class="credit-selector__input"
      data-testid="original-credit"
      :value="returnCreditTypes.REFUND"
      @focus="updateFocus"
      @focusout="updateFocus"
      @blur="updateFocus"
    >
      <base-card
        ref="originalCredit"
        class="credit-selector__card"
        tag="span"
        :selected="selected === returnCreditTypes.REFUND"
      >
        <span class="credit-selector__card-content">
          <base-icon
            class="credit-selector__card-icon"
            name="credit-card"
          />
          <span class="credit-selector__label">
            <base-text
              class="credit-selector__heading"
              type="heading"
            >
              <render-content>
                {{ $content.moduleCreditSelector.ogPaymentHeading }}
              </render-content>
            </base-text>
            <credit-selector-callout
              v-if="hasRefundFee && !shouldHideFeesInCards"
              :loading="feesLoading"
            >
              <handling-fees-render-content
                :handlingFee="refundFee.amount"
                :policyHandlingFee="receipt?.policyHandlingFee"
                :perProductHandlingFee="receipt?.perProductHandlingFee"
                :isLoopPosReturnMethod="isLoopPosReturnMethod"
              />
            </credit-selector-callout>
            <base-text
              class="credit-selector__payment-copy"
              type="body-2"
            >
              <render-content>
                {{ $content.moduleCreditSelector.ogPaymentCopyPerProduct }}
              </render-content>
            </base-text>
          </span>
        </span>
      </base-card>
    </radio-input>

    <base-text
      v-if="customizedHandlingFeeDisclaimer"
      type="body-2"
    >
      <render-content v-if="feesLoading">
        {{ $content.moduleCreditSelector.handlingFeesLoading }}
      </render-content>
      <render-content
        v-if="hasRefundFee && !feesLoading"
        :data="customizedHandlingFeeDisclaimer"
      >
        {{ $content.moduleCreditSelector.handlingFeeDisclaimer }}
      </render-content>
    </base-text>
  </div>
</template>

<script>
import {
  BaseCard,
  BaseIcon,
  BaseText,
  RadioInput,
} from '@loophq/design-system';
import { formatCurrency } from '@/js/helpers/formatCurrency';
import CreditSelectorCallout from '@/views/ReviewPage/CreditSelectorCallout';
import firstMile from '@/js/constants/firstMile';
import { featureFlags } from '@/js/constants/featureFlags';
import { returnCreditTypes, storeCreditTypes } from '@/js/constants/returns';
import { track } from '@/js/segment';
import HandlingFeesRenderContent from './CreditSelector/HandlingFeesRenderContent.vue';

export default {
  components: {
    BaseCard,
    BaseIcon,
    BaseText,
    CreditSelectorCallout,
    RadioInput,
    HandlingFeesRenderContent
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    available: {
      type: Object,
      required: true,
    },
    receipt: {
      type: Object,
      required: false,
    },
  },
  emits: [
    'input',
  ],
  data() {
    return {
      refundTotals: null,
      loading: false,
      initialCartLength: 0,
    };
  },
  computed: {
    displayCurrency() {
      return this.$store.getters['currencies/displayCurrency'];
    },
    exchangeRate() {
      return this.$store.getters['currencies/exchangeRate'];
    },
    refundFee() {
      return this.$store.state.fees.handlingFee?.fees.refund;
    },
    creditFee() {
      return this.$store.state.fees.handlingFee?.fees.credit;
    },
    exchangeFee() {
      return this.$store.state.fees.handlingFee?.fees.exchange;
    },
    feesLoading() {
      return this.$store.state.fees.loading;
    },
    bonusCredit() {
      return this.$store.state.totals.totals.credit.bonus.storeCreditBonus;
    },
    exchangeRatio() {
      return this.$store.state.totals.totals.computed.exchangeRatio;
    },
    returnCreditTypes() {
      return returnCreditTypes;
    },
    storeCreditTypes() {
      return storeCreditTypes;
    },
    giftCardIncentiveType() {
      return this.order.return_policy.gift_card_incentive_type;
    },
    hasExchangeFee() {
      return this.exchangeFee?.amount > 0;
    },
    hasRefundFee() {
      // Use exchange fee if ratio is greater than .5
      if (this.exchangeRatio >= .5) {
        return this.hasExchangeFee;
      }

      return this.refundFee?.amount > 0;
    },
    hasStoreCreditFee() {
      // Use exchange fee if ratio is greater than .5
      if (this.exchangeRatio >= .5) {
        return this.hasExchangeFee;
      }

      return this.creditFee?.amount > 0;
    },
    hasStoreCreditBonus() {
      return this.bonusCredit > 0;
    },
    shouldHideFeesInCards() {
      // Hide fee message for Return Coverage
      if (this.$store.getters.hasReturnCoverage) {
        return true;
      }

      // Hide fee message if feature flag exists
      return this.$store.getters.hasFeature(featureFlags.RETURNS_PORTAL_HIDE_FEES_ON_CREDIT_TYPE_SELECTION_CARDS);
    },
    selected: {
      get() {
        if (this.order.creditType == returnCreditTypes.GIFT) {
          return returnCreditTypes.GIFT;
        } else if (this.order.creditType == returnCreditTypes.REFUND) {
          return returnCreditTypes.REFUND;
        } else {
          return '';
        }
      },
      set(val) {
        let creditType;
        let storeCreditType;
        switch (val) {
          case returnCreditTypes.GIFT:
            creditType = val;
            storeCreditType = storeCreditTypes.GIFTCARD;
            this.trackSelectionType('storeCreditSelected');
            break;
          case returnCreditTypes.REFUND:
            creditType = val;
            // this isn't relevant to refunds, but we use "GIFTCARD" as our default for storeCreditType
            storeCreditType = storeCreditTypes.GIFTCARD;
            this.trackSelectionType('originalPaymentSelected');
            break;
          default:
            break;
        }

        this.$emit('input', { creditType, storeCreditType });
      }
    },
    availableOptions() {
      const options = { ...this.available };
      if (this.$store.getters.wasCashPurchased) {
        if ('refund' in options) {
          options.refund = false;
        }
      }
      return options;
    },
    customizedHandlingFeeDisclaimer() {
      const hasCustomizations = this.$content.moduleCreditSelector.handlingFeeDisclaimer;
      if (hasCustomizations) {
        return {
          handlingFee: this.formatAmount(this.refundFee?.amount),
        };
      }
      // hide disclaimer if no customizations for the message
      return null;
    },
    isLoopPosReturnMethod() {
      return this.$store.getters.order?.returnMethod?.name === firstMile.LOOP_POS;
    },
    analytics() {
      return this.$store.getters['analytics/getData'];
    },
    shouldShowCreditFee() {
      return !this.receipt?.perProductHandlingFee || this.receipt?.perProductHandlingFee == 0;
    },
  },
  methods: {
    formatAmount(amount) {
      const amountForDisplay = amount * this.exchangeRate;
      return formatCurrency(amountForDisplay, this.displayCurrency);
    },
    trackSelectionType(t) {
      track('selectedCreditType', { ...this.analytics, storeCreditType: t });
    },
    updateFocus(el) {
      switch (el.target.value) {
        case returnCreditTypes.GIFT:
          this.$refs.storeCredit.$el.classList.toggle('focused');
          break;
        case returnCreditTypes.REFUND:
          this.$refs.originalCredit.$el.classList.toggle('focused');
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$block: '.credit-selector';

#{$block} {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-300);

  &__header {
    display: flex;
  }

  &__heading {
    font-weight: 700;
  }

  &__card {
    padding: var(--spacing-300) var(--spacing-300);
    box-shadow: none;
    transition: box-shadow var(--transition-300);
    width: 100%;

    &:hover:not(.selected),
    &:focus:not(.selected) {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 12%), 0 6px 12px rgba(0, 0, 0, 8%);
    }

    #{$block}__heading,
    #{$block}__card-icon {
      flex-shrink: 0;
      color: var(--body-color);
    }

    &.selected {
      border: 1px solid var(--grey-900);
      box-shadow: 0 0 0 1px var(--grey-900);

      #{$block}__heading,
      #{$block}__card-icon {
        color: var(--heading-color);
      }
    }

    &.focused {
      outline: 4px solid var(--theme-outline-color);
    }
  }

  &__card-content {
    display: flex;
    gap: var(--spacing-300);
  }

  // hides the custom radio button UI element from DOM to allow card styling
  // while maintaining radio input functionality.
  &__input {
    &:deep(.radio-input__label) {
      &::before {
        display: none !important;
      }
    }
  }

  &:deep(.radio-input__input.focus-visible+.radio-input__label) {
    &::after {
      display: none !important;
    }
  }

  &__body {
    margin-top: var(--spacing-100);
  }

  &__payment-copy {
    margin-top: var(--spacing-100);
  }
}
</style>
