export const returnAuthorizationTypes = {
  SINGLE_UNIT_AMOUNT: 'single_unit_amount',
  FULL_AMOUNT: 'full_amount',
};

export const returnTypes = {
  INSTANT: 'instant',
  REGULAR: 'regular',
};

export const returnCreditTypes = {
  GIFT: 'gift',
  REFUND: 'refund',
  CREDIT: 'credit',
};

export const storeCreditTypes = {
  GIFTCARD: 'gift-card',
};

export const selectedReturnMethod = {
  ORIGINAL_PAYMENT_METHOD: 'original-payment-method',
  STORE_CREDIT: 'store-credit',
  STORE_CREDIT_SHOP_CASH_BONUS: 'store-credit-shop-cash-bonus',
};

export const returnStates = {
  CANCELLED: 'cancelled',
  CLOSED: 'closed',
  EXPIRED: 'expired',
  OPEN: 'open',
  PROCESSED: 'processed',
  PROCESSING: 'processing',
  REVIEW: 'review',
};
