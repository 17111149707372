export default {
  data: function () {
    return {
      //if empty, it means that the default is stored within the html for dynamic copy
      //it will stop being dynamic if the string is filled
      customizations: {
        setting: {
          analytics: {
            ga_key: null
          }
        },
        //deafult style settings
        style: {
          theme: {
            type: 'light',
            sharpness: 'round'
          },
          branding: {
            logo: '',
            nav_logo: '',
            brand_color: '#3d51ff',
            header_font: '',
            copy_font: '',
            favicon: ''
          },
          background: {
            image: '',
            color_1: '#f9fafb',
            color_2: '',
            size: 'cover'
          },
          font: {
            header: '',
            header_weight: '',
            header_case: '',
            copy: '',
            copy_weight: '',
            copy_case: '',
            button_weight: '',
            button_case: ''
          }
        },
        email: {
          info: {
            from_email: 'help@store.com',
            sender_name: 'Return Assistant'
          },
          style: {
            color_primary: '#000000',
            font: 'Source Sans(Default)'
          },
          template: {
            expiration_notice: '',
            expiration_reminder: '',
            out_of_stock: '',
            return_confirmation: '',
            stripe_refund: '',
            stripe_transaction: '',
            return_confirmation_keep_items: ''
          }
        },
        //default content settings
        content: {
          analytics: {
            ga_key: null
          },
          lookup: {
            disabled: 'Our returns portal is currently disabled.',
            header: 'Returns and exchanges',
            field_1__placeholder: 'Order number',
            field_2__placeholder: 'Shipping zip code',
            button_text: 'Get started',
            tooltip__copy: 'Your order number can be found in your order confirmation email.',
            tooltip__link__header: 'Can\'t find it? Get in touch:',
            gift_link: 'exchange a gift',
            error__not_found: '',
            shop_link: '',
            shop_link__copy: '',
            gift__header: 'Returning or exchanging a gift?',
            gift__cta: 'Start here',
            form__header: 'We need a few more details!',
            form__subheader: 'Please provide additional details so that we can help you with this return.',
            form__back: 'Gift order lookup'
          },
          gift: {
            header: 'Gift returns and exchanges',
            subheader: 'The following information can be found on the packing slip from the original order.',
            button: 'Get started',
            missing_info: 'Don\'t have your order information?',
            footer__header: 'Not returning a gift?',
            footer__cta: 'Start here',
            form__header: 'We need a few more details!',
            form__subheader: 'We\'ll need your email and shipping address to send a shipping label and tracking information.'
          },
          exchange: {
            header_copy: 'Choose your items to return',
            subheader_copy: 'Returnable until',
            past_returnable_copy: 'The return window for these item(s) have closed',
            selected_item__return: 'Return this item',
            continue_button: 'Continue with return'
          },
          manual_review: {
            failure__header: 'We’re sorry, but this item is no longer returnable.',
            failure__copy: 'Unfortunately, this item is no longer in a condition that can be returned to us. If you have any questions, don’t hesitate to get in touch:'
          },
          modal: {
            exchange_button: '',
            return_button: 'Return item',
            slide_header: '',
            footer__header: 'Don\'t see the product you want?',
            footer__button_text: 'Shop with return credit',
            replace__header: 'Replace only',
            replace__copy: 'This product is unique with no variations available. It can only be replaced for the same product.',
            replace__button_text: 'Replace this item',
            size_chart_url: '',
            size_chart_copy: '',
            size_chart_option_name: 'size'
          },
          credit: {
            header_copy: 'Let\'s find you something new',
            header_copy_2: 'Choose a credit method',
            store_credit__header: 'Store credit',
            store_credit__copy: 'Receive a gift card code via email once your return has been approved.',
            shop_now__header: 'Shop Now',
            shop_now__copy: '',
            no_thanks__header: 'No, thanks',
            no_thanks__copy: 'Receive store credit or a refund back to your original payment method instead.',
            og_payment__header: 'Original Payment Method',
            og_payment__copy: 'Receive a refund to original payment method once your return is approved.',
            exchange_only: 'Original payment method refunds are not an available credit option for one or more items in this return.',

          },
          shop_now: {
            header__heading: 'Shop now',
            header__subheading: 'Use your return value to shop instantly.',
            cart__heading: 'Your Bag'
          },
          review: {
            header_copy: 'Review your return',
            receive: '',
            send_back: '',
            submit_button: 'Submit Return'
          },
          confirm: {
            header_copy: 'Your return summary',
            keep_item__modal__header: 'Keep the item!',
            keep_item__modal__copy: 'Heads up, you <span>don\'t need to send back</span> this return to get your refund or exchange. We hope that you find a good home for the items(s) by giving to a friend or donating.',
            keep_item__modal__button: 'Got it, thanks!',
            keep_item__page__header: 'We\'re sorry you didn\'t love your order!',
            keep_item__page__copy: 'No need to send back this return. We\'ll issue your refund, gift card, or exchange once we receive and process your request.',
            return_address__header: 'Return address',
            return_address__copy: 'Please return the items to this address below for your return to be processed.',
            instructions_header: 'How to return your item',
            instructions: [
              'Securely pack items. If available, please use original packaging.',
              'Attach your return label to the package.',
              'Drop off the package within 28 days to your nearest carrier location.',
              'We\'ll issue your refund, gift card, or exchange once we approve your return.'
            ],
            return_items__header: ''
          },
          international: {
            header: 'Return Address',
            copy: 'Please return the items to the address below',
            instructions_header: 'How to return your item',
            instructions: [
              'Securely pack items. If available, please use original packaging.',
              'Attach your return label to the package.',
              'Drop off the package at your nearest carrier location.',
              'We\'ll issue your refund, gift card, or exchange once we receive your return.'
            ]
          },
          footer: {
            copy: ['Questions?', 'Contact'],
            link: '',
            link_copy: ''
          },
          global: {
            handling_fee_name: 'handling fee',
            error_module__header: 'Looks like there was an error',
            error_module__copy: '',
            error_module__contact: 'If the problem persists, contact us at',
            error_module__button: 'Try again'
          }
        }
      }
    };
  }
};
