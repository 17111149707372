export const states = new Map([
  ['expired', 'moduleExpired'],
]);

export const outcomes = new Map([
  ['review', 'moduleReview'],
  ['keep', 'moduleKeepItem'],
  ['donate', 'moduleLabel'],
]);

export const labels = new Map([
  ['prepaid', 'modulePrepaidLabels'],
  ['code', 'moduleDefaultReturnCode'],
  ['uspsCode', 'moduleUspsReturnCode'],
  ['fedExCode', 'moduleReturnCode'],
  ['uspsQrSelector', 'moduleUspsQrSelector'],
  ['inpostCode', 'moduleInpostReturnCode'],
  ['asdaCode', 'moduleAsdaReturnCode'],
  ['evriCode', 'moduleEvriReturnCode'],
  ['royalMailCode', 'moduleRoyalMailReturnCode'],
  ['label', 'moduleLabel'],
  ['labelWithPackingSlip', 'moduleLabelWithPackingSlip'],
  ['labelError', 'moduleLabelError'],
  ['labelProcessing', 'moduleLabelProcessing'],
  ['thirdPartyLabelsWaiting', 'moduleLabelOnTheWay'],
  ['queued', 'moduleQueuedLabel'],
  ['happyReturns', 'moduleHappyReturnsDropoff'],
  ['shopifyPos', 'moduleStoreDropOff'],
  ['loopPos', 'moduleStoreDropOff'],
  ['veho', 'moduleVehoHomePickup'],
  ['vehoError', 'moduleVehoError'],
  ['returnTracking', 'moduleReturnTracking'],
  ['canadaPostCode', 'moduleCanadaPostReturnCode'],
  ['colissimoRnCode', 'moduleColissimoRnReturnCode'],
  ['colissimoRkCode', 'moduleColissimoRkReturnCode'],
  ['dhlDeCode', 'moduleDhlDeReturnCode'],
  ['dpdUkCode', 'moduleDpdUkReturnCode'],
  ['royalMailReboundCode', 'moduleRoyalMailReboundReturnCode'],
  ['upsMiCode', 'moduleUpsMiReturnCode'],
  ['hermesCode', 'moduleHermesReturnCode'],
  ['posteItalianeCode', 'modulePosteItalianeReturnCode'],
]);

export const labelComponents = new Map([
  ['prepaid', 'PrepaidLabels'],
  ['code', 'ReturnCode'],
  ['fedExCode', 'ReturnCode'],
  ['uspsCode', 'ReturnCode'],
  ['inpostCode', 'ReturnCode'],
  ['asdaCode', 'ReturnCode'],
  ['evriCode', 'ReturnCode'],
  ['canadaPostCode', 'ReturnCode'],
  ['royalMailCode', 'ReturnCode'],
  ['uspsQrSelector', 'UspsQrSelector'],
  ['label', 'PrintLabel'],
  ['labelProcessing', 'LabelProcessing'],
  ['labelError', 'LabelError'],
  ['happyReturns', 'HappyReturnsDropOff'],
  ['shopifyPos', 'ShopifyPosDropOff'],
  ['loopPos', 'LoopPosDropOff'],
  ['colissimoRnCode', 'ReturnCode'],
  ['colissimoRkCode', 'ReturnCode'],
  ['dhlDeCode', 'ReturnCode'],
  ['dpdUkCode', 'ReturnCode'],
  ['royalMailReboundCode', 'ReturnCode'],
  ['upsMiCode', 'ReturnCode'],
  ['hermesCode', 'ReturnCode'],
  ['posteItalianeCode', 'ReturnCode'],
]);

export const views = {
  QR_CODE: 'qrCode',
};
