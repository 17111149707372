import { availableMetrics } from '@/js/controllers/metrics';

const PRE_PAID = 'pre-paid';
const HAPPY_RETURNS = 'happy-returns';
const SHOPIFY_POS = 'shopify-pos';
const LOOP_POS = 'loop-pos';
const VEHO = 'veho';
const LABEL_CHOICE = 'label-choice';
const INPOST = 'inpost';
const ASDA = 'asda';
const EVRI = 'evri';
const SEKO = 'seko';
const DHL_SEKO = 'DHL-SEKO';
const PITNEY_BOWES_STANDARD = 'pitney-bowes-standard';
const PITNEY_BOWES_STANDARD_NEWGISTICS = 'NEWGISTICS';
const COLISSIMO_RN = 'colissimo-rn';
const COLISSIMO_RK = 'colissimo-rk';
const DHL_DE = 'dhl-de';
const DPD_UK = 'dpd-uk';
const ROYAL_MAIL_REBOUND = 'royal-mail';
const UPS_MI = 'ups-mi';
const POSTNORD_DK = 'postnord-dk';
const HERMES = 'hermes';
const POSTE_ITALIANE = 'poste-italiane';

export default {
  PRE_PAID,
  HAPPY_RETURNS,
  SHOPIFY_POS,
  LOOP_POS,
  VEHO,
  LABEL_CHOICE,
  INPOST,
  EVRI,
  ASDA,
  SEKO,
  DHL_SEKO,
  PITNEY_BOWES_STANDARD,
  COLISSIMO_RN,
  COLISSIMO_RK,
  DPD_UK,
  ROYAL_MAIL_REBOUND,
  UPS_MI,
  POSTNORD_DK,
  HERMES,
  POSTE_ITALIANE,
  DHL_DE,

  METRICS: {
    [HAPPY_RETURNS]: availableMetrics.HAPPY_RETURNS_OFFERED,
    [VEHO]: availableMetrics.VEHO_OFFERED,
    [INPOST]: availableMetrics.INPOST_OFFERED,
    [ASDA]: availableMetrics.ASDA_OFFERED,
    [EVRI]: availableMetrics.EVRI_OFFERED,
    [POSTE_ITALIANE]: availableMetrics.POSTE_ITALIANE_OFFERED,
  },

  FORMATTED_NAMES: {
    [PRE_PAID]: 'Box and ship',
    [HAPPY_RETURNS]: 'Happy Returns®',
  },

  SUSTAINABLE_RETURN_METHODS: {
    [HAPPY_RETURNS]: '16 shipments',
  },

  // Always hide the sustainable banner for certain return methods
  UNSUSTAINABLE_RETURN_METHODS: [
    VEHO
  ],

  RETURN_METHODS_SCROLLTO: [
    VEHO,
  ],

  RETURN_METHODS_NEED_VALIDATED: [
    VEHO,
    EVRI,
    SEKO,
  ],

  RETURN_METHODS_THAT_REPLACE_BOX_AND_SHIP: [
    INPOST,
    EVRI,
    ASDA,
    SEKO,
    PITNEY_BOWES_STANDARD,
    COLISSIMO_RN,
    COLISSIMO_RK,
    DHL_DE,
    DPD_UK,
    ROYAL_MAIL_REBOUND,
    UPS_MI,
    POSTNORD_DK,
    HERMES,
    POSTE_ITALIANE,
  ],

  REBOUND_UK_CARRIERS: [
    INPOST,
    EVRI,
    ASDA
  ],

  REBOUND_SLUGS: {
    [INPOST]: 'inpost-drop-off-select',
    [ASDA]: 'asda-drop-off',
    [ROYAL_MAIL_REBOUND]: 'gb-royal-mail-paperless-drop-off',
    [DPD_UK]: 'gb-dpd-uk-paperless-drop-off',
    dhl_de_label: 'dhl-de-connect-label-drop-off',
    dhl_de_qr: 'dhl-de-retoure-paperless-drop-off',
    ups_mi_label: 'ups-mi-label-drop-off',
    ups_mi_qr: 'ups-mi-paperless-drop-off',
    [COLISSIMO_RK]: 'fr-colissimo-drop-off-paperless-rk',
    evri_qr: 'evri-drop-off-qr-code',
    evri_label: 'evri-drop-off-label',
    colissimo_label_rn: 'fr-colissimo-drop-off-label-rn',
    colissimo_qr_rn: 'fr-colissimo-drop-off-paperless-rn',
    [POSTNORD_DK]: 'dk-retur-pakke',
    hermes_label: 'hermes-label-drop-off',
    hermes_qr: 'hermes-paperless-drop-off',
    poste_italiane_label: 'poste-italiane-drop-off-c',
    poste_italiane_qr: 'poste-italiane-paperless-drop-off-c',
  },

  LABEL_CHOICES: {
    [EVRI]: {
      qrCode: 'evri-drop-off-qr-code',
      label: 'evri-drop-off-label'
    },
    [SEKO]: {
      qrCode: 'qr',
      label: 'label'
    },
    [PITNEY_BOWES_STANDARD]: {
      qrCode: 'qr',
      label: 'label'
    },
    [COLISSIMO_RN]: {
      qrCode: 'fr-colissimo-drop-off-paperless-rn',
      label: 'fr-colissimo-drop-off-label-rn'
    },
    [HERMES]: {
      qrCode: 'hermes-paperless-drop-off',
      label: 'hermes-label-drop-off'
    },
    [POSTE_ITALIANE]: {
      qrCode: 'poste-italiane-paperless-drop-off-c',
      label: 'poste-italiane-drop-off-c'
    },
    [UPS_MI]: {
      qrCode: 'ups-mi-paperless-drop-off',
      label: 'ups-mi-label-drop-off'
    },
    [DHL_DE]: {
      qrCode: 'dhl-de-retoure-paperless-drop-off',
      label: 'dhl-de-connect-label-drop-off'
    },
  },

  RETURN_METHODS_WITH_LABEL_CHOICES: [
    EVRI,
    SEKO,
    PITNEY_BOWES_STANDARD,
    COLISSIMO_RN,
    HERMES,
    POSTE_ITALIANE,
    UPS_MI,
    DHL_DE,
  ],

  SINGLE_CHOICE_RETURN_METHODS: [
    SEKO,
    DHL_SEKO,
    INPOST,
    EVRI,
    ASDA,
    PITNEY_BOWES_STANDARD_NEWGISTICS,
    DPD_UK,
    ROYAL_MAIL_REBOUND,
    COLISSIMO_RK,
    POSTNORD_DK,
    HERMES,
  ],

  REBOUND_SINGLE_CHOICE_METHODS: [
    DPD_UK,
    ROYAL_MAIL_REBOUND,
    COLISSIMO_RK,
    POSTNORD_DK,
    HERMES,
  ],

  DENSE_POPULATION_MILES: 2,
  REGULAR_POPULATION_MILES: 10,

  VEHO_SCHEDULABLE_DAYS: 7,
  VEHO_HOLIDAYS: {
    '1,1': 'New Years Day',
    '7,4': 'July 4th',
    '12,25': 'Christmas Day',
    // Some holidays dont have concrete dates, but instead has 'nth day of kth week'
    // For example, Thanksgiving is Thursday of the fourth week
    // month,week,day
    '10,4,4': 'Thanksgiving Day',
  },
  VEHO_PICKUP_OPTIONS: [
    'Leave at door',
    'Meet at door',
    'Meet outside'
  ],
  VEHO_SPECIAL_INSTRUCTIONS_MAX_CHAR: 256,
  VEHO_NEXT_ICONS: [
    'package-alt',
    'shipment',
    'chat',
    'circle-tick',
  ],
  RETURN_CODE_NEXT_ICONS: [
    'package-alt',
    'qr-code',
    'circle-tick',
  ],
  VEHO_STATE_NEEDS_SCHEDULED: 'needs_scheduled',
  VEHO_STATE_TIMED_OUT: 'timed_out',
};
