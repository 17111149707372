<template>
  <main
    class="shop-now"
  >
    <Teleport to="#header-bar-actions">
      <cart-button
        :cart-length="cart.length"
        :toggle-cart="openCart"
      />
    </Teleport>
    <h1
      class="visually-hidden"
    >
      <render-content>
        {{ $content.pageShopNow.heading }}
      </render-content>
    </h1>
    <transition
      name="slideup"
      appear
    >
      <section class="shop-now__content">
        <div class="shop-now__content-wrap">
          <aside
            :class="sideBar.className"
          >
            <transition
              name="fadein"
              appear
            >
              <skeleton-nav v-if="!navigation.length" />
            </transition>
            <transition
              name="fadein"
              appear
            >
              <accordion-nav
                v-if="sideBar.showAccordionNav"
                :items="navigation"
                @update="updateNav"
                @track="trackNavigation"
              />
            </transition>
          </aside>
          <mobile-nav
            v-if="handleMobileNav"
            class="shop-now__mobile-nav"
            :items="navigation"
          />
          <div
            v-if="isRootPath && hasMoreThanOneCollection && isMobile"
            class="shop-now__collection"
          >
            <transition
              v-if="!navigation.length"
              name="fadein"
              appear
            >
              <span
                class="shop-now__loader"
              >
                <span
                  class="shop-now__skeleton-title"
                  aria-hidden="true"
                ></span>
                <skeleton-product
                  v-for="product in previewProductCount"
                  :key="product"
                />
              </span>
            </transition>
            <shop-now-collection-preview
              v-for="item in allCollections"
              :key="item.id"
              :collection="item"
            />
          </div>
          <shop-now-collection
            v-else
            class="shop-now__collection"
          />
        </div>
      </section>
    </transition>
    <router-view />
  </main>
</template>

<script>
import { mapNavigation, mapActive, getFirstCollection } from './Shop/shoputil';
import ShopNowCollection from '@/views/Shop/ShopNowCollection';
import ShopNowCollectionPreview from '@/views/Shop/ShopNowCollectionPreview';
import MobileNav from '@/views/Shop/ShopNowMobileNav';
import AccordionNav from '@/components/navigation/AccordionNav';
import SkeletonNav from '@/components/feedback/SkeletonNav';
import SkeletonProduct from '@/components/feedback/SkeletonProduct';
import { smoothscroll } from '@/js/helpers';
import { enablePageScroll } from 'scroll-lock';
import CartButton from './Shop/Sidecart/CartButton.vue';

export default {
  name: 'ShopNow',
  components: {
    AccordionNav,
    CartButton,
    MobileNav,
    ShopNowCollection,
    ShopNowCollectionPreview,
    SkeletonNav,
    SkeletonProduct,
  },
  beforeRouteEnter (to, from, next) {
    // Scroll to top
    smoothscroll();

    // We don't actually want to hold this up
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // Ignore route changes that are product/cart,
    // we don't need to load a new collection in that case
    const toCollection = !(['shopnowproduct', 'shopnowcart'].includes(to.name) || ['shopnowproduct', 'shopnowcart'].includes(from.name));

    enablePageScroll();

    if (toCollection) {
      // Scroll to top
      smoothscroll();

      const initialCollection = getFirstCollection(this.$store.state.tabs);
      // Get collection when navigating within this page
      // Needed here because this route is not an actual sub route
      const collection = to.params.id ? { id: +to.params.id } : initialCollection;

      // Set page from query params
      const page = to.query.page || 1;

      // Get collection in vuex store
      this.$store.dispatch('shopnow/getCollection', { collection, payload: { page, 'order_id': this.order.id }, resetMax: to.path !== from.path });

      // Update navigation
      const active =
        to.params.id
          ? +to.params.id
          : initialCollection
            ? initialCollection.id
            : null;

      this.$store.commit('shopnow/updateNav', mapActive(this.navigation, active));
    }

    next();
  },

  computed: {
    shop() {
      return this.$store.state.shopContents;
    },
    shopNowStore() {
      return this.$store.state.shopnow;
    },
    cart() {
      return this.$store.getters.order.cart ? this.$store.getters.order.cart : [];
    },
    order() {
      return this.$store.getters.order;
    },
    navigation() {
      return this.shopNowStore.navigation;
    },
    isMobile() {
      return this.$screen.width <= 680;
    },
    hasMoreThanOneCollection() {
      if (this.navigation.length === 1 && this.navigation[0].children?.length > 0) {
        return true;
      }
      return this.navigation.length > 1;
    },
    handleMobileNav() {
      return this.isMobile && this.hasMoreThanOneCollection;
    },
    isRootPath() {
      return !this.$route.params.id;
    },
    previewProductCount() {
      return this.$screen.width < 1365 ? 2 : 3;
    },
    allCollections() {
      return this.$store.state.tabs;
    },
    sideBar() {
      const sideBarWebView = this.hasMoreThanOneCollection && !this.isMobile;

      return {
        className: sideBarWebView && 'shop-now__sidebar',
        showAccordionNav: sideBarWebView,
      };
    }
  },
  async created() {
    this.$store.commit('setShopNowNavigation', true);
    if (this.$route.query.return_key) {
      this.$route.meta.previous = `/return/${this.$route.query.return_key}`;
    }

    if (!this.$store.getters.order) {
      this.$router.push('/');
    }

    // Set page if available
    const page = this.$route.query.page || 1;

    // We need a way of tracking the active collection
    let collections = this.$store.state.tabs.length ? this.$store.state.tabs : null;

    // This is a page refresh if tabs is not available,
    // we're preloading it in the previous screen
    if (!collections) {
      this.$store.commit('shopnow/setProperty', { key: 'loading', value: true });
      collections = await this.$store.dispatch('getTabs');
    }

    // Get the first actual collection
    const initialCollection = getFirstCollection(collections);

    // Use the route id, fall back to the initial collection
    const active = this.$route.params.id ? { id: +this.$route.params.id } : initialCollection ? initialCollection : null;
    this.$store.commit('shopnow/updateNav', mapNavigation(collections, active));

    // Get collection data
    // Ignored if we're preloading
    if (!this.shopNowStore.collection.products && !this.shopNowStore.preloading) {
      this.$store.dispatch('shopnow/getCollection', { collection: active, payload: { page, 'order_id': this.order.id } });
    }

    // Reset preloading flag if needed
    this.$store.commit('shopnow/setProperty', { key: 'preloading', value: false });
  },
  methods: {
    updateNav(nav) {
      this.$store.commit('shopnow/updateNav', nav);
    },
    openCart() {
      this.$router.push('/shop/cart');
      this.$trackEvent('cart opened');
    },
    trackNavigation(title) {
      const viewAll = 'View all';
      if (title === viewAll) {
        this.$trackEvent('shop now view all');
      }
      this.$trackEvent('navigated to new shop now collection');
    }
  },
};
</script>

<style lang="scss" scoped>
$block: '.shop-now';

.slideup-enter-active,
.slideup-leave-active {
  transition: opacity 0.24s ease-out, transform 0.24s cubic-bezier(0.46, 0.12, 0.11, 0.99);
}

.slideup-enter,
.slideup-leave-to {
  opacity: 0;
}

#{$block} {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  &__cart-button {
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    border-radius: var(--theme-corners);
  }

  &__cart-icon {
    margin-right: 0.5rem;
  }

  &__cart-text {
    margin-left: 0.25em;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 30vh;
    width: 100%;
    padding: 5rem 2.5rem;
    max-width: $max-site-width + 80px; // Adding 2.5rem of padding on both side

    &.dark-background {
      color: white;
      text-shadow: 0 0 10px rgba(#3c4043, 0.2);
    }

    &.light-background {
      color: #3c4043;
      text-shadow: 0 0 10px rgba(white, 0.2);
    }
  }

  &__heading {
    color: inherit;
    margin-bottom: 0.5em;

    @include responsive-font(3.5rem, 2rem);
  }

  &__subheading {
    color: inherit;
    transition-delay: 0.12s;

    @include responsive-font(1.5rem, 1.0625rem);
  }

  &__content {
    width: 100%;
    background-color: white;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    backface-visibility: hidden;
    will-change: opacity, transform;
    transition-delay: 0.24s;
    box-shadow: inset 0 -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 4%);
  }

  &__content-wrap {
    width: 100%;
    padding: 5rem 2.5rem;
    max-width: $max-site-width + 80px; // Adding 2.5rem of padding on both side
    display: flex;
    align-items: flex-start;
  }

  &__sidebar {
    position: relative;
    flex: 1 0 20%;
    min-width: 16rem;
    margin-right: 5rem;

    @supports (position: sticky) {
      position: sticky;
      top: 7rem;
    }
  }

  &__collection {
    flex-basis: 80%;
    flex-grow: 4;
  }

  &__skeleton-title {
    @include skeleton;

    grid-column: 1 / 4;
    max-width: 360px;
    width: 100%;
    height: 3rem;
  }

  &__loader {
    display: grid;

    /* autoprefixer: ignore next */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1.5rem;
    margin-bottom: var(--spacing-800);
  }
}

@media screen and (max-width: $break-large) {
  #{$block} {
    &__sidebar {
      margin-right: 2rem;
    }

    &__loader {
      /* autoprefixer: ignore next */
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    &__header {
      align-items: center;
    }

    &__heading {
      text-align: center;
    }

    &__subheading {
      text-align: center;
    }

    &__sidebar {
      display: none;
    }

    &__content-wrap {
      width: 100%;
      padding: 1.5rem 0.75rem;
      max-width: $max-site-width + 80px; // Adding 2.5rem of padding on both side
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__mobile-nav {
      width: 100%;
      margin-bottom: var(--spacing-500);
    }

    &__cart-button {
      padding: 0.5rem 0.75rem;
    }

    &__cart-icon {
      margin-right: 0.25rem;
    }

    &__cart-text {
      display: none;
    }

    &__collection,
    &__container {
      width: 100%;
    }

    &__loader {
      margin-bottom: var(--spacing-800);
      grid-gap: 0.75rem;
    }
  }
}
</style>
