import { defineRule, configure } from 'vee-validate';
import { required, email } from '@vee-validate/rules';
import Store from '@/store';

export default function () {
  const content = Store.getters['content'];
  const names = { required, email };

  //rewrite the rules to use content with defineRule
  Object.keys(names).forEach((rule) => {
    defineRule(rule, names[rule]);
    configure({
      generateMessage: (context) => {
        return content?.validation?.[context.rule.name];
      }
    });
  });

}
