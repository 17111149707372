<template>
  <div class="return-summary">
    <sustainable-banner
      v-if="showSustainabilityBannerOnRsp"
      class="return-summary__sustainable-banner-rsp"
      :return-method="returnMethod"
    />
    <button
      class="return-summary__toggle"
      @click.prevent="toggle"
    >
      <base-text
        type="display-medium"
        class="return-summary__heading"
      >
        <render-content>
          {{ $content.moduleReturnSummary.heading }}
        </render-content>
      </base-text>
      <transition name="fadein">
        <base-loader
          v-if="loading"
          class="return-summary__loader"
          size="small"
        />
      </transition>
      <base-text
        type="body-2"
        tag="span"
        class="return-summary__details"
      >
        <render-content>
          {{ $content.moduleReturnSummary.toggle }}
        </render-content>
        <base-icon
          class="return-summary__icon"
          :class="{ active }"
          name="down"
        />
      </base-text>
    </button>
    <summary-section
      class="return-summary__section-credits"
      :class="{ active }"
      :active="active"
      v-bind="credits"
    />
    <summary-section
      v-if="debits.products.length"
      class="return-summary__section-debits"
      :class="{ active }"
      :active="active"
      v-bind="debits"
    />
    <summary-section
      v-if="handlingFee"
      class="return-summary__section-handling-fee"
      :class="{ active }"
      :active="true"
      v-bind="handlingFee"
      :loading="isHandlingFeeLoading"
    />
    <summary-section
      v-if="hasGiftCard"
      class="return-summary_section"
      :class="{ active }"
      :active="true"
      v-bind="storeCreditBonus"
    />
    <div
      v-if="showSustainabilityBannerOnRcp"
    >
      <hr class="return-summary__divider" />
      <sustainable-banner
        class="return-summary__sustainable-banner-rcp"
        :return-method="returnMethod"
      />
    </div>

    <slot name="actions"></slot>
    <hr class="return-summary__divider" />
    <div
      v-if="hasTotal"
      class="return-summary__section total"
    >
      <div class="return-summary__line total-summary-line">
        <div class="return-summary__line left">
          <div>
            <base-text
              type="display-small"
              class="return-summary__label total-label"
            >
              {{ total.label }}
            </base-text>
            <base-text
              v-if="showRefundMethod"
              type="body-2"
            >
              {{ total.refundMethod }}
            </base-text>
          </div>
          <base-tooltip
            v-if="useOrderCurrencyAsPresentment"
            class="return-summary__tooltip"
            :content="total.tooltip"
          >
            <base-icon
              class="return-summary__tooltip-icon"
              name="info"
            />
          </base-tooltip>
        </div>

        <base-text
          type="display-large"
          class="return-summary__value"
        >
          {{ total.amount }}
        </base-text>
      </div>
      <div
        v-if="hasSplitRefundsEnabled && splitRefundsBreakdown?.isSplitRefund && !loading"
        class="flex flex-align-center flex-justify-between"
      >
        <base-text
          class="return-summary__label"
          type="body-2"
        >
          {{ $content.moduleReturnSummary.giftCardMethod }}
        </base-text>
        <base-text
          class="return-summary__value"
          type="body-2"
        >
          <display-price :amount="splitRefundsBreakdown.storeCreditAmount" />
        </base-text>
      </div>
      <div
        v-if="hasSplitRefundsEnabled && splitRefundsBreakdown?.isSplitRefund && splitRefundsBreakdown?.refundLabel && !loading"
        class="return-summary__line"
      >
        <base-text
          class="return-summary__label"
          type="body-2"
        >
          <render-content :data="{ cardType: originalPaymentCardInfo?.brand, last4: originalPaymentCardInfo?.last4}">
            {{ splitRefundsBreakdown.refundLabel }}
          </render-content>
        </base-text>
        <base-text
          class="return-summary__value"
          type="body-2"
        >
          <display-price :amount="splitRefundsBreakdown.refundAmount" />
        </base-text>
      </div>

      <shop-later-gift-card-status
        v-if="showShopLaterGiftCardStatus"
        class="return-summary__shop-later-gc-status-banner"
        :giftCard="getGiftCardFromReturn"
        :currency="receipt.currency"
      />
    </div>
  </div>
</template>

<script>
import {
  BaseText,
  BaseIcon,
  BaseLoader,
  BaseTooltip,
  AlertBanner,
} from '@loophq/design-system';
import SummarySection from './SummarySection';
import Store from '@/store';
import { formatCurrency as format } from '@/js/helpers/formatCurrency';
import formatProduct from '@/js/helpers/formatProduct';
import ShopLaterGiftCardStatus from '@/views/StatusPage/ShopLater/GiftCardStatus';
import { featureFlags } from '@/js/constants/featureFlags';
import SustainableBanner from './SustainableBanner';
import firstMile from '@/js/constants/firstMile';
import { returnCreditTypes, storeCreditTypes } from '@/js/constants/returns';
import DisplayPrice from '@/components/globals/DisplayPrice';
import StoreCreditOnlyBadge from '@/components/badges/StoreCreditOnlyBadge';
/**
 * Takes a number and formats it into a currency. Also applies parens around if needed.
 * @param {number} amount - The amount to format, in cents
 * @returns {string}
 */
const formatCurrency = (amount, addParens, currency = Store.state.currency) => {
  if (typeof amount !== 'number') {
    return amount;
  }

  const formatted = format(Math.abs(amount), currency);
  return amount > 0 && addParens ? `(${formatted})` : formatted;
};

/**
 * Removes any items with a value of 0 and formats the value using a money formatter
 * @param {{ label: string, value: number }[]} items - An array of label/value pairs to format
 * @returns {{ label: string, value: string }[]}
 */
const formatItems = (items, currency) => {
  return items
    .filter(item => item && item.value !== 0)
    .map((item) => {
      return {
        ...item,
        value: formatCurrency(item.value, item.addParens ?? false, currency)
      };
    });
};

/**
 * @param {object} items - An object where the keys of the object map to the product ids
 * @param {object[]} products - An array of products
 * @param {number} products.id - The items object keys are matched against this
 * @returns {array} An array of products formatted for the product card
 */
const getProducts = ({ items = {}, products, includeLineItemFees = true }) => {
  return Object.keys(items)
    .reduce((acc, itemId) => {
      // These values may be strings or numbers so we're doing a weak comparison
      const product = products.find((item) => item.id == itemId || item.variant_id == itemId);
      if (product) {
        const amount = items[itemId]?.amount ? items[itemId].amount : 1;
        const useStruckPrice = items[itemId]?.useStruckPrice ?? false;
        const struckPrice = useStruckPrice ? { struckPrice: items[itemId].struckPrice } : {};
        const products = Array(amount).fill(
          formatProduct({
            ...product,
            price: items[itemId].price ?? items[itemId].itemNet,
            itemFee: items[itemId].itemFee ? `-${formatCurrency(items[itemId].itemFee, false, product.currency)}` : 0,
            ...(items[itemId].lineItemFees ? { lineItemFees: items[itemId].lineItemFees } : {}),
            ...(product.lineItemFees && !includeLineItemFees ? { lineItemFees: {} } : {}),
            ...struckPrice
          })
        );

        return [
          ...acc,
          ...products
        ];
      }

      return acc;
    }, []);
};

export default {
  components: {
    ShopLaterGiftCardStatus,
    BaseText,
    BaseIcon,
    BaseLoader,
    BaseTooltip,
    AlertBanner,
    SummarySection,
    SustainableBanner,
    DisplayPrice,
    StoreCreditOnlyBadge,
  },
  props: {
    hasGiftCard: {
      type: Boolean,
      required: false
    },
    returning: {
      type: Object,
      required: false
    },
    purchasing: {
      type: [Object, Array],
      required: false
    },
    receipt: {
      type: Object,
      required: true
    },
    lineItems: {
      type: Array,
      required: false,
    },
    removedLineItems: {
      type: Array,
      required: false,
      default: ()=>[],
    },
    exchanges: {
      type: Array,
      required: false,
    },
    exchangeType: {
      type: String,
      required: false,
    },
    reimbursements: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    shopLaterOffer: {
      type: Object,
      required: false,
    },
    return: {
      type: Object,
      required: false,
    },
    returnMethod: {
      type: Object,
      required: false,
    },
  },
  emits: ['toggle-more-information'],
  data() {
    return {
      active: true,
      storeCreditTypes,
    };
  },
  computed: {
    displayCurrency() {
      return this.$store.getters['currencies/displayCurrency'];
    },
    exchangeRate() {
      return this.$store.getters['currencies/exchangeRate'];
    },
    orderCurrencyExchangeRate() {
      return this.$store.getters['currencies/orderCurrencyExchangeRate'];
    },
    useOrderCurrencyAsPresentment() {
      return !this.$store.getters['currencies/useOrderCurrencyAsPresentment'] && this.$store.getters['currencies/isMultiCurrency'];
    },
    orderCurrency() {
      return this.$store.getters['currencies/orderCurrency'];
    },
    creditBreakdown() {
      const content = this.$content.moduleReturnSummary;
      return formatItems([
        {
          label: content.creditSubtotal,
          value: this.receipt.returnedItemSubtotal * this.exchangeRate,
        },
        this.shippingTotal,
        this.dutiesTotal,
        {
          label: content.taxCredit,
          value: this.receipt.taxesIncluded ? this.$content.moduleReturnSummary.taxesIncludedText : this.receipt.returnedItemTax * this.exchangeRate,
        },
      ], this.displayCurrency);
    },
    credits() {
      const shippingCredit = this.shippingTotal?.rawValue ?? 0;
      const dutiesCredit = this.dutiesTotal?.rawValue ?? 0;
      const returnedItemCredit = this.receipt.returnedItemFee
        ? this.receipt.returnedItemSubtotal + Math.abs(this.receipt.returnedItemTax) + shippingCredit
        : this.receipt.returnedItemCredit + Math.abs(dutiesCredit) + shippingCredit;

      return {
        label: this.$content.moduleReturnSummary.creditHeading,
        value: formatCurrency(returnedItemCredit * this.exchangeRate, true, this.displayCurrency),
        breakdown: this.creditBreakdown,
        products: getProducts({ items: this.returning, products: this.lineItems }),
        removedProducts: this.removedLineItems.map(item => formatProduct(item))
      };
    },
    debits() {
      const breakdown = formatItems([
        {
          label: this.$content.moduleReturnSummary.newItemSubtotal,
          value: this.receipt.newItemSubtotal * this.exchangeRate,
        },
        {
          label: this.$content.moduleReturnSummary.storefrontBonus,
          value: (this.receipt.bonusUsed - this.receipt.shopNowDiscount) * this.exchangeRate,
          addParens: true,
        },
        {
          label: this.$content.moduleReturnSummary.exchangeOrderShippingLabel,
          value: this.receipt.exchangeOrderShippingTotal * this.exchangeRate,
        },
        {
          label: this.$content.moduleReturnSummary.tax,
          value: this.receipt.taxesIncluded ? this.$content.moduleReturnSummary.taxesIncludedText : this.receipt.newItemTax * this.exchangeRate,
        }
      ], this.displayCurrency);

      return {
        label: this.$content.moduleReturnSummary.debitHeading,
        value: formatCurrency(this.receipt.newItemTotal * this.exchangeRate, false, this.displayCurrency),
        breakdown: breakdown,
        products: getProducts({
          items: this.purchasing,
          products: this.exchanges,
          includeLineItemFees: false,
        }),
        purchasing: true,
        receipt: this.receipt
      };
    },
    handlingFee() {
      let handlingFeeTotal = formatCurrency(this.receipt.handlingFee * this.exchangeRate, false, this.displayCurrency);

      if (this.hasReturnSummaryImprovements) {
        handlingFeeTotal = `-${formatCurrency(this.receipt.handlingFee * this.exchangeRate, false, this.displayCurrency)}`;
      }

      return {
        label: this.$content.global.handlingFee,
        value: handlingFeeTotal,
        policyHandlingFee: this.receipt.policyHandlingFee,
        perProductHandlingFee: this.receipt.perProductHandlingFee
      };
    },
    storeCreditBonus() {
      let value = formatCurrency(this.receipt.giftCardBonus * this.exchangeRate, false, this.displayCurrency);
      if (this.hasReturnSummaryImprovements) {
        value = `+${formatCurrency(this.receipt.giftCardBonus * this.exchangeRate, false, this.displayCurrency)}`;
      }

      if (this.receipt.giftCardBonus) {
        return {
          label: this.$content.moduleReturnSummary.storeCreditBonus,
          value,
        };
      }
      return null;
    },
    isHandlingFeeLoading() {
      return this.$store.getters['fees/loading'];
    },
    hasConditionalReimbursements() {
      return !!this.reimbursements;
    },
    shippingTotal() {
      const hasShippingTotalEnabled = this.reimbursements?.shipping?.creditEnabled || this.reimbursements?.shipping?.refundEnabled;

      if (
        this.receipt.shippingTotal &&
        (!this.hasConditionalReimbursements || hasShippingTotalEnabled)
      ) {
        return {
          label: this.$content.moduleReturnSummary.shippingTotal,
          value: formatCurrency(this.receipt.shippingTotal * this.exchangeRate, false, this.displayCurrency),
          rawValue: this.receipt.shippingTotal,
        };
      }
      return null;
    },
    dutiesTotal() {
      const hasDutiesTotalEnabled = this.reimbursements?.duties?.creditEnabled || this.reimbursements?.duties?.refundEnabled;

      if (
        this.receipt.dutiesTotal &&
        (!this.hasConditionalReimbursements || hasDutiesTotalEnabled)
      ) {
        return {
          label: this.$content.moduleReturnSummary.dutiesTotal,
          value: formatCurrency(this.receipt.dutiesTotal * this.exchangeRate, false, this.displayCurrency),
          rawValue: this.receipt.dutiesTotal,
        };
      }
      return null;
    },
    hasTotal() {
      const isEqualized = this.receipt.total === 0;
      const hasBreakdown = this.creditBreakdown.length > 2;
      const returnCredit = !isEqualized || hasBreakdown;
      const amountOwed = Math.sign(this.receipt.total) > 0;
      return returnCredit || amountOwed;
    },
    originalPaymentCardInfo() {
      const { original_payment_transactions } = this.$store.state.order;
      if (!original_payment_transactions?.length) {
        return null;
      }

      const { last4, brand } = original_payment_transactions[0];
      if (!last4 || !brand) {
        return null;
      }

      return {
        last4,
        brand,
      };
    },
    splitRefundsBreakdown() {
      if (!this.receipt.splitRefundsBreakdown) return;

      let refundLabel = this.$content.moduleReturnSummary.originalPaymentMethod;
      if (this.originalPaymentCardInfo) {
        refundLabel = this.$content.moduleReturnSummary.originalPaymentMethodCard;
      }

      return {
        ...this.receipt.splitRefundsBreakdown,
        isSplitRefund: this.receipt.splitRefundsBreakdown.storeCreditAmount > 0 && this.receipt.splitRefundsBreakdown.refundAmount,
        refundLabel,
      };
    },
    presentmentRefund() {
      const content = this.$content.moduleReturnSummary;
      const label = this.receipt.total > 0 ? content.multiCurrencyRefund : content.multiCurrencyOwed;

      return {
        label,
        amount: formatCurrency(this.receipt.total * this.orderCurrencyExchangeRate, false, this.orderCurrency),
      };
    },
    getGiftCardFromReturn() {
      return this.return.giftCard[0];
    },
    total() {
      const content = this.return ? this.$content.moduleReturnSummary : this.$content.pageReview;
      let amount = formatCurrency(this.receipt.total * this.exchangeRate, false, this.displayCurrency);
      let label = this.useOrderCurrencyAsPresentment ? content.estimatedRefund : content.refund;
      let refundMethod = content.originalPaymentMethod;
      let tooltip = content.estimatedRefundTooltip;

      if (this.receipt.total < 0) {
        label = this.useOrderCurrencyAsPresentment ? content.estimatedOwed : content.amountOwed;
        refundMethod = null;
        tooltip = content.estimatedUpsellTooltip;
      } else if (this.isSplitRefund) {
        label = this.$content.moduleReturnSummary.estimatedRefund;
        refundMethod = null;
        if (this.$store.state.return.creditType === returnCreditTypes.GIFT) {
          refundMethod = content.giftCardMethod;
        }
      } else if (this.hasGiftCard) {
        label = this.useOrderCurrencyAsPresentment ? content.estimatedGiftCard : content.giftCard;
        refundMethod = content.giftCardMethod;
      }

      if (this.showShopLaterGiftCardStatus) {
        amount = formatCurrency(this.getGiftCardFromReturn.value * this.exchangeRate, false, this.displayCurrency);
      }

      return {
        label,
        amount,
        refundMethod,
        tooltip,
      };
    },
    showShopLaterGiftCardStatus() {
      return this.$settings.shopLaterEnabled
        && this.shopLaterOffer?.state === 'accepted'
        && this.return.giftCard;
    },
    hasReturnSummaryImprovements() {
      // TODO: remove this with the return-summary-positive-negative-identifiers feature flag removal
      return this.$store.getters.hasFeature(featureFlags.RETURN_SUMMARY_NEGATIVE_POSITIVE_IDENTIFIERS);
    },
    returnMethodLoading() {
      return this.$store.getters['firstMile/loading'];
    },
    showSustainabilityBannerOnRsp() {
      return this.$settings.sustainabilityBannerEnabled
        && !!this.return
        && this.return?.outcome !== 'keep'
        && !firstMile.UNSUSTAINABLE_RETURN_METHODS.includes(this.returnMethod?.name);
    },
    showSustainabilityBannerOnRcp() {
      return this.$settings.sustainabilityBannerEnabled
        && !this.return
        && !this.returnMethodLoading
        && !!this.returnMethod
        && !firstMile.UNSUSTAINABLE_RETURN_METHODS.includes(this.returnMethod?.name);
    },
    hasSplitRefundsEnabled() {
      return this.$store.getters.settings.enableSplitRefunds;
    },
    isSplitRefund() {
      if (this.return && this.hasSplitRefundsEnabled && this.return.hasRefund && this.return.hasGiftCard && !this.return.exchanges?.length) {
        return true;
      }
      return this.$store.getters['return/isSplitReturn'];
    },
    showRefundMethod() {
      return this.hasSplitRefundsEnabled && this.$store.state.return.creditType === returnCreditTypes.GIFT;
    }
  },
  methods: {
    toggle() {
      this.active = !this.active;

      this.$trackEvent('summary details toggled');
    },
  }
};
</script>

<style lang="scss" scoped>
$block: '.return-summary';

#{$block} {
  color: var(--grey-800);

  &__toggle {
    @include reset-button;

    width: 100%;
    display: flex;
    align-items: center;

    &.focus-visible {
      outline: none;
      background-color: var(--grey-200);
      border-radius: var(--corners);
    }
  }

  &__heading {
    margin-right: var(--spacing-200);
  }

  &__loader {
    margin-right: var(--spacing-200);
  }

  &__details {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: auto;
    color: var(--grey-600);
  }

  &__icon {
    // Visually centering this icon
    margin-top: 1px;
    transition: transform var(--transition-500);

    &.active {
      transform: rotate(0.5turn);
    }
  }

  &__section {
    &#{$block}__section.active {
      margin-top: 0;

      & + & {
        border-top: 2px solid var(--grey-200);
        margin-top: var(--spacing-300);
      }
    }

    &.has-line {
      margin-top: var(--spacing-400);
      border-top: 2px solid var(--grey-200);
    }

    &.total {
      margin-top: var(--spacing-200);
    }
  }

  &__sustainable-banner {
    &-rsp {
      margin-bottom: var(--spacing-400);
    }
  }

  &__line {
    display: flex;
    align-items: flex-end;

    &.left {
      display: flex;
      align-items: center;
      gap: var(--spacing-100);
    }
  }

  &__tooltip {
    &-icon {
      width: 20px;
    }

    :deep(.base-tooltip__content) {
      max-width: 280px;
    }
  }

  &__divider {
    width: 100%;
    border: 0;
    border-top: 2px solid var(--grey-200);
    margin: var(--spacing-400) 0;
  }

  &__label {
    flex-grow: 1;

    &.bold {
      font-weight: 500;
    }
  }

  &__value {
    flex-shrink: 0;
    font-weight: 500;
  }

  &__shop-later-gc-status-banner {
    margin-top: var(--spacing-600);
  }

  &__shop-later-banner {
    margin: var(--spacing-400) var(--spacing-100) var(--spacing-600) var(--spacing-100);
  }

  .total-summary-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
