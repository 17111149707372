<template>
  <div class="review-page">
    <div class="review-page__container">
      <base-text
        class="review-page__heading"
        tag="h1"
        type="display-xlarge"
      >
        <render-content>
          {{ $content.pageReview.heading }}
        </render-content>
      </base-text>
      <column-layout class="review-page__main">
        <base-card
          v-if="firstMileLoading"
        >
          <skeleton-text
            :lines="1"
            width="20%"
          />
          <skeleton-text
            :lines="1"
            type="heading"
          />
          <skeleton-text :lines="10" />
        </base-card>

        <return-method-selector
          v-else-if="firstMileOptions.length"
          :options="firstMileOptions"
        />

        <return-items
          class="review-page__items"
          v-bind="{ sending, receiving, order }"
          @checkCreditType="checkCreditType"
        />

        <base-card>
          <base-text
            type="display-medium"
            tag="h2"
          >
            {{ $content.moduleCustomerInfo.heading }}
          </base-text>
          <customer-information
            v-if="address"
            :can-edit="true"
            class="review-page__customer"
            :customer="customer"
            :address="address"
            @update="handleAddressUpdate"
          />
        </base-card>

        <template #sidebar>
          <return-summary
            class="review-page__summary"
            v-bind="{
              receipt,
              returning: receipt.returnedItems,
              lineItems,
              purchasing: receipt.newItems,
              exchanges: [...sending, ...receiving],
              exchangeType,
              hasGiftCard: order.creditType === 'gift',
              reimbursements,
              loading: totalsLoading,
              returnMethod: order.returnMethod,
            }"
          >
            <template #actions>
              <component
                :is="creditSelectorComponent"
                v-if="showCreditSelector"
                class="review-page__types"
                :order="order"
                :available="available"
                :is-split-refund="isSplitRefund"
                :receipt="receipt"
                @input="updateCredit"
                @toggle-more-information="toggleMoreInformation"
              />

              <base-text
                v-if="showRefundOnly"
              >
                <render-content>
                  {{ $content.pageReview.refundOnlyCopy }}
                </render-content>
              </base-text>

              <exchange-selector
                v-if="showExchangeSelector"
                class="review-page__types"
                :initial-value="exchangeType"
                :exchanges="totals.owed.exchanges"
                @input="updateExchange"
                @show-instant-exchange-explanation="showInstantExchangeExplanation"
              />

              <instant-exchange-details
                v-if="showInstantExchangeDetails"
                :instant-exchange-window="instantExchangeWindow"
                @show-instant-exchange-explanation="showInstantExchangeExplanation"
              />

              <instant-return-details
                v-if="showInstantReturnDetails"
                :instant-return-window="instantReturnWindow"
                @show-instant-return-explanation="showInstantReturnExplanation"
              />

              <return-selector
                v-if="showReturnSelector"
                class="review-page__types"
                :order="order"
                :initial-value="returnType"
                @input="updateReturn"
                @show-instant-return-explanation="showInstantReturnExplanation"
              />
            </template>
          </return-summary>
          <div class="review-page__actions">
            <payment-details
              class="review-page__payment"
              v-bind="{ acceptsPayment, totals, exchangeType, returnType }"
              :checkout-type="checkoutType"
              @update="updatePayment"
              @update3DS="update3DS"
              @submit="onStripeSubmit"
            />
            <policy-conditions
              v-if="showPolicyConditions"
            />
            <base-button
              class="review-page__submit"
              type="primary"
              :activated="submitting"
              :disabled="submitDisabled"
              @click="submit"
            >
              <render-content>
                {{ $content.pageReview.submit }}
              </render-content>
            </base-button>
            <base-button
              class="review-page__back-button"
              type="secondary"
              :to="previousPage.to"
              @click="toPreviousPage"
            >
              <render-content>
                {{ previousPage.text }}
              </render-content>
            </base-button>
          </div>
          <veho-privacy-policy v-if="showVehoPrivacyPolicy" />
          <support-section
            :page="reviewPage"
            class="review-page__support"
          />
        </template>
      </column-layout>
    </div>
    <Teleport to="#app-target">
      <instant-exchange-dialog
        :is-active="showMoreInstantExchange"
        v-bind="{customizationData, components}"
        @close="showMoreInstantExchange = false"
      />
      <instant-return-dialog
        v-if="hasInstantRefundsFF"
        v-bind="{customizationData, components}"
        :is-active="showMoreInstantReturn"
        @close="showMoreInstantReturn = false"
      />
    </Teleport>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  BaseText,
  BaseButton,
  BaseCard
} from '@loophq/design-system';
import { featureFlags } from '@/js/constants/featureFlags';
import ColumnLayout from '@/components/layout/ColumnLayout';
import SupportSection from '@/components/app/SupportSection';
import PaymentDetails from './ReviewPage/PaymentDetails';
import CreditSelector from './ReviewPage/CreditSelector';
import InstantExchangeDialog from './ReviewPage/InstantExchangeDialog';
import InstantReturnDialog from './ReviewPage/InstantReturnDialog';
import ReturnMethodSelector from './ReviewPage/ReturnMethodSelector';
import ExchangeSelector from './ReviewPage/ExchangeSelector';
import InstantExchangeDetails from './ReviewPage/InstantExchangeDetails';
import InstantReturnDetails from './ReviewPage/InstantReturnDetails';
import ReturnItems from './ReviewPage/ReturnItems';
import ReturnSelector from './ReviewPage/ReturnSelector';
import VehoPrivacyPolicy from './ReviewPage/ReturnMethods/VehoPrivacyPolicy';
import CustomerInformation from './StatusPage/CustomerInformation';
import ReturnSummary from './StatusPage/ReturnSummary';
import PolicyConditions from './ReviewPage/PolicyConditions';
import formatProduct from '@/js/helpers/formatProduct';
import { generateOnstoreUrl, navigateToOnstore } from '@/js/onstore';
import objectToArray from '@/js/helpers/objectToArray';
import Return from '@/js/controllers/return';
import changeCase from 'change-object-case';
import SkeletonText from '@/components/feedback/SkeletonText';
import { replacePlaceholderUsingObject } from '@/js/utility/replacePlaceholderUsingObject';
import { formatCurrency, getCurrencyAmount } from '@/js/helpers/formatCurrency';
import firstMile from '@/js/constants/firstMile';
import {
  returnCreditTypes,
  returnTypes,
  storeCreditTypes
} from '@/js/constants/returns';
import { logError } from '@/js/helpers/errors';
import uuid from 'uuid/v4';
import useStorageMethods from '@/js/mixins/store';
import { trackRefund } from '@/js/helpers/trackEvent';
import { track } from '@/js/segment';
const { setStore } = useStorageMethods();
import CreditSelectorNew from './ReviewPage/CreditSelector.new';
import { experiments } from '@/js/constants/experiments';
import { customerPortalPageNames } from '@/js/constants/segment';


changeCase.options = { recursive: true, arrayRecursive: true };

const addAmountToNewItems = (newItems = {}, itemsInCart = []) => {
  // These values may be strings or numbers so we're doing a weak comparison
  const findCartItems = (id) => itemsInCart.filter((item) => item.id == id || item.variant_id == id);

  return Object.fromEntries(
    Object.entries(newItems)
      .map(([key, value]) => {
        return [
          key,
          {
            ...value,
            amount: findCartItems(key)?.length ?? 0
          }
        ];
      })
  );
};

const checkAvailable = (order, type, isSplitRefund) => {
  const refunded = objectToArray(order.line_items)
    .filter((item) => item.returnType === 'credit');
  const refundedNotAllowed = isSplitRefund ? refunded.some((item) => item.allowed[type]) : refunded.every((item) => item.allowed[type]);

  return !(!refundedNotAllowed || !order.eligibility[type] || order.enabled[type] === 'no');
};

export default {
  name: 'ReviewPage',
  components: {
    BaseText,
    BaseButton,
    BaseCard,
    ColumnLayout,
    CreditSelector,
    CreditSelectorNew,
    CustomerInformation,
    ExchangeSelector,
    InstantExchangeDetails,
    InstantExchangeDialog,
    InstantReturnDialog,
    InstantReturnDetails,
    PaymentDetails,
    ReturnItems,
    ReturnSelector,
    ReturnSummary,
    ReturnMethodSelector,
    SkeletonText,
    SupportSection,
    VehoPrivacyPolicy,
    PolicyConditions,
  },

  // eslint-disable-next-line
  beforeRouteLeave(to, from) {
    if (this.onStoreEnabled && this.hasCart && to?.name === this.previousPage?.to?.name) {
      const answer = window.confirm(
        this.$content.pageReview.browserBackButtonAlert
      );
      if (!answer) {
        return false;
      }
    }
    return true;
  },
  data() {
    return {
      item: null,
      token: null,
      intentId: null,
      stripe: null,
      elements: null,
      submitting: false,
      showRefundOnly: false,
      showMoreInstantExchange: false,
      showMoreInstantReturn: false,
      components: {
        paragraph: {
          component: BaseText,
          options: {}
        }
      },
      firstMile,
      signifydSessionId: null,
      returnTypes,
      checkFraudLoading: false,
      reviewPage: customerPortalPageNames.REVIEW_PAGE,
    };
  },
  computed: mapState({
    order() {
      return this.$store.getters.order;
    },
    return: (state) => state.return,
    source: (state) => state.source,
    totals() {
      return this.$store.state.totals.totals;
    },
    acceptsPayment() {
      return this.order.stripe || this.order.shopify_payments;
    },
    firstMileOptions() {
      if (this.inShopLaterFlow) {
        return [];
      }
      return this.$store.getters['firstMile/options'](this.address?.zip);
    },
    receipt() {
      const receipt = this.$store.state.totals.receipt;
      if (receipt) {
        const newItems = receipt.newItems;
        const amount = receipt.amountToAuthorize;
        const showHold = this.showExchangeSelector && this.isInstantExchange;
        return {
          ...receipt,
          // Exchange for the same item will not be in the order's cart, but rather in the 'receiving' items array
          newItems: addAmountToNewItems(newItems, this.receiving),
          amountToAuthorize: showHold && amount ? amount : null
        };
      }

      return {};
    },
    lineItems() {
      return Object.values(this.order.line_items);
    },
    // All items the user is receiving, both exchanges and anything from the shop now cart
    receiving() {
      const cart = this.order.cart ? this.order.cart : [];
      const exchanged = objectToArray(this.order.line_items)
        .filter((item) => {
          return item.returnType === 'exchange' && !item.returned_at;
        })
        .map((item) => {
          return {
            ...item.new_variant,
            image: item.new_variant.image ?? item.image,
            title: this.displayTitle(item.new_variant) ?? ''
          };
        });

      return [
        ...cart.map(item => {
          return {
            ...item,
            inCart: true
          };
        }),
        ...exchanged
      ].map((item) => {
        // eslint-disable-next-line no-unused-vars
        const { price, ...rest } = item;
        return formatProduct(rest);
      });
    },
    // All items being sent back regardless of type
    sending() {
      if (this.inShopLaterFlow) {
        return objectToArray(this.return.lineItems)
          .filter((item) => item.returnType)
          .map((item) => {
            // eslint-disable-next-line no-unused-vars
            const { price, ...rest } = item;
            return formatProduct(rest);
          });
      }

      return objectToArray(this.order.line_items)
        .filter((item) => item.returnType)
        .map((item) => {
          // eslint-disable-next-line no-unused-vars
          const { price, ...rest } = item;
          return formatProduct(rest);
        });
    },
    // If this return has items that are specifically being refunded
    hasRefunded() {
      return objectToArray(this.order.line_items)
        .filter((item) => item.returnType === 'credit')
        .length > 0;
    },
    onStoreEnabled() {
      return this.order?.enabled?.on_store_api === 'yes';
    },
    hasCart() {
      return this.$store.state.cartToken;
    },
    isHandlingFeeLoading() {
      return this.$store.getters['fees/loading'];
    },
    submitDisabled() {
      const needsCredit = !this.order.creditType && this.totals.computed.remainingCredit > 0;
      const needsUpsell = this.totals.computed.net < 0 && (this.totals.computed.net * -1) > this.totals.settings.stripeMin;
      const needsExchange = this.showExchangeSelector && !this.exchangeType;
      const needsPaymentIntent = !this.hasStripe3dsFF || !this.intentId;
      const needsAuthorization = this.canInstantExchange && this.isInstantExchange && !(this.token || !needsPaymentIntent);
      const returnMethodIsValid = this.firstMileOptions.length === 0 || this.$store.getters['firstMile/returnMethodIsValid'];
      const needsPolicyAcceptance = this.$settings.requirePolicyAcceptance && !this.$store.state.return.acceptsPolicyConditions;
      const needsPayment = !(this.token || !needsPaymentIntent);

      if (this.hasInstantRefundsFF &&
        !this.inShopLaterFlow &&
        this.instantReturnEnabled &&
        this.creditType === returnCreditTypes.REFUND &&
        (!this.return.returnType || this.return.returnType === returnTypes.INSTANT)
      ) {
        return needsPayment || this.token?.card?.funding === 'prepaid';
      }

      return needsCredit ||
        (needsUpsell && needsPayment) ||
        (needsUpsell && !this.acceptsPayment) ||
        (needsExchange || needsAuthorization) ||
        this.isHandlingFeeLoading ||
        needsPolicyAcceptance ||
        !returnMethodIsValid ||
        this.$store.getters['firstMile/loading'] ||
        this.checkFraudLoading;
    },
    checkoutType() {
      return this.hasExchangeOrderCheckout ? 'shopify-payments' : 'stripe';
    },
    hasExchangeOrderCheckout() {
      return this.$store.getters.hasFeature(featureFlags.EXCHANGE_ORDER_CHECKOUT) && this.$settings.checkoutForUpsell;
    },
    creditTypesEnabled() {
      const types = [
        returnCreditTypes.REFUND,
        returnCreditTypes.GIFT
      ];
      return types.reduce((acc, cur) => {
        if (this.order.enabled[cur] === 'yes') {
          return acc + 1;
        }

        return acc;
      }, 0);
    },
    previousPage() {
      const isFlowBEnabled = this.$store.getters.experimentVariation(experiments.RETURNS_PORTAL_FLOW_B_EXPERIMENT) === experiments.FLOW_B;
      const text = (this.cameFromShopNow
        && !isFlowBEnabled)
        ? 'continueShoppingButton'
        : 'goBack';
      const returnPath = isFlowBEnabled ? 'exchange' : 'review';
      let cartUrl = { name: 'shop' };

      if (this.onStoreEnabled) {
        cartUrl = this.$store.state.isEmbeddedPortal ? null : generateOnstoreUrl(returnPath);
      }

      const needsCredit = this.hasRefunded && this.creditTypesEnabled > 1;
      const hasShopNowForAll = this.order.return_policy.shop_now_for_all_enabled;

      let backUrl;
      if (this.$store.state.return.hasShopLaterOffer) {
        // shop later offer flows only have shop now to return to
        backUrl = { name: 'shop' };
      } else {
        backUrl = (needsCredit || hasShopNowForAll) && !isFlowBEnabled
          ? { name: 'credit' }
          : { name: 'exchange' };
      }

      return {
        to: this.cameFromShopNow ? cartUrl : backUrl,
        text: this.$content.pageReview[text]
      };
    },
    exchangeType() {
      return this.return.exchangeType;
    },
    creditType() {
      return this.return.creditType;
    },
    returnType() {
      return this.return.returnType;
    },
    address() {
      return this.$store.getters['return/address'];
    },
    customer() {
      return this.$store.getters['return/customer'];
    },
    showExchangeSelector() {
      const hasCartItems = this.order.cart ? this.order.cart.length > 0 : false;
      const hasNoExchanges = this.lineItems.filter((item) => item.resolution).every((item) => item.resolution === 'return');

      return this.acceptsPayment &&
        this.canInstantExchange &&
        (!hasNoExchanges || hasCartItems) &&
        !this.hasReviewLineItem &&
        !this.isFullKeep &&
        !this.instantExchangeRequired &&
        !this.hasInstantRefundsFF &&
        !this.inShopLaterFlow;
    },
    showReturnSelector() {
      const excludeInstantReturns = this.lineItems.filter((item) => item.resolution)
        .some(item => item?.excluded?.instantReturns);

      return this.hasInstantRefundsFF &&
        this.instantReturnEnabled &&
        !this.instantReturnRequired &&
        (this.return.creditType === returnCreditTypes.REFUND || !this.return.creditType) &&
        this.acceptsPayment &&
        (this.canInstantExchange || this.canInstantReturn) &&
        !this.hasReviewLineItem &&
        !this.isFullKeep &&
        !this.inShopLaterFlow &&
        !excludeInstantReturns;
    },
    isFullKeep() {
      return this.$store.state?.totals?.shippable?.outcome === 'keep';
    },
    reimbursements() {
      const creditType = this.order.creditType;
      const reimbursement = this.order.return_policy?.reimbursement;

      return {
        duties: {
          creditEnabled: creditType === returnCreditTypes.GIFT && reimbursement?.duties_store_credit_enabled,
          refundEnabled: creditType === returnCreditTypes.REFUND && reimbursement?.duties_refunds_enabled,
        },
        shipping: {
          creditEnabled: creditType === returnCreditTypes.GIFT && reimbursement?.shipping_store_credit_enabled,
          refundEnabled: creditType === returnCreditTypes.REFUND && reimbursement?.shipping_refunds_enabled,
        }
      };
    },
    totalsLoading() {
      return this.$store.getters['totals/isLoading'];
    },
    available() {
      return {
        gift: checkAvailable(this.order, returnCreditTypes.GIFT, this.isSplitRefund),
        refund: checkAvailable(this.order, returnCreditTypes.REFUND, this.isSplitRefund),
      };
    },
    canInstantExchange() {
      const excludeInstantExchange = this.lineItems.filter((item) => item.resolution)
        .some(item => item?.excluded?.instantExchange);

      return this.totals.owed.exchanges.instantExchangeEnabled &&
        this.totals.owed.exchanges.amountToAuthorize > 0 &&
        this.totals.owed.exchanges.amountToCharge > 0 &&
        !excludeInstantExchange;
    },
    canInstantReturn() {
      return this.totals.owed.instantReturnEnabled &&
        this.totals.owed.amountToAuthorize > 0 &&
        this.totals.owed.amountToCharge > 0;
    },
    showCreditSelector() {
      if (this.totals.computed.net > 0) {
        const { refund, gift } = this.available;
        if (gift && refund) {
          return true;
        }
      }
      return false;
    },
    isSplitRefund() {
      return this.$store.getters['return/isSplitReturn'];
    },
    firstMileLoading() {
      return this.$store.getters['firstMile/loading'];
    },
    instantExchangeRequired() {
      return this.order.return_policy.instant_exchange_required;
    },
    instantReturnRequired() {
      return this.order.return_policy.instant_return_required;
    },
    instantReturnEnabled() {
      return this.totals.owed.instantReturnEnabled;
    },
    showInstantExchangeDetails() {
      const hasCartItems = this.order.cart ? this.order.cart.length > 0 : false;
      const hasNoExchanges = this.lineItems.filter((item) => item.resolution).every((item) => item.resolution === 'return');

      return this.canInstantExchange &&
        this.instantExchangeRequired &&
        (!hasNoExchanges || hasCartItems) &&
        !this.hasReviewLineItem &&
        !this.hasInstantRefundsFF &&
        !this.inShopLaterFlow;
    },
    showInstantReturnDetails() {
      const excludeInstantReturns = this.lineItems.filter((item) => item.resolution)
        .some(item => item?.excluded?.instantReturns);

      return this.hasInstantRefundsFF &&
        this.instantReturnEnabled &&
        this.instantReturnRequired &&
        this.order.creditType !== returnCreditTypes.GIFT &&
        !this.inShopLaterFlow &&
        !this.hasReviewLineItem &&
        !excludeInstantReturns;
    },
    customizationData() {
      const amountToAuthorize = this.hasInstantRefundsFF ? this.totals.owed.amountToAuthorize : this.totals.owed.exchanges.amountToAuthorize;

      return {
        amountToAuthorize: formatCurrency(amountToAuthorize, this.$settings.currency),
        instantExchangeWindow: this.totals.owed.exchanges.instantExchangeWindow,
        instantReturnWindow: this.totals.owed.instantReturnWindow,
      };
    },
    instantExchangeWindow() {
      return this.totals.owed.exchanges.instantExchangeWindow;
    },
    instantReturnWindow() {
      return this.totals.owed.instantReturnWindow;
    },
    hasInstantRefundsFF() {
      return this.$store.getters.hasFeature(featureFlags.INSTANT_REFUNDS);
    },
    hasStripe3dsFF() {
      return this.$store.getters.hasFeature(featureFlags.PAYMENTS_STRIPE_3DS2);
    },
    inShopLaterFlow() {
      const { hasShopLaterOffer, shopLater: shopLaterOffer } = this.return;
      const shopLaterOfferExists = hasShopLaterOffer && shopLaterOffer !== null;
      const shopLaterOfferIsObject = shopLaterOfferExists && typeof shopLaterOffer === 'object';

      return shopLaterOfferIsObject && this.$settings.shopLaterEnabled;
    },
    returnKey() {
      return this.$store.state.return?.key;
    },
    showVehoPrivacyPolicy() {
      return this.order.returnMethod?.name === firstMile.VEHO;
    },
    hasReviewLineItem() {
      return this.lineItems.find((item) => item.outcome === 'review');
    },
    showPolicyConditions() {
      return this.$settings.requirePolicyAcceptance;
    },
    isInstantExchange() {
      return this.exchangeType === returnTypes.INSTANT;
    },
    hasFraudToolsSetting() {
      return this.$settings.fraudTools;
    },
    isWarrantyReturn() {
      return this.return.lineItems.some(
        ({ outcomesSetByWorkflow }) => outcomesSetByWorkflow.some((outcome) => outcome?.name === 'Warranty')
      );
    },
    creditSelectorComponent() {
      return this.isSplitRefund ? 'credit-selector-new' : 'credit-selector';
    },
    analytics() {
      return this.$store.getters['analytics/getData'];
    },
    cameFromShopNow() {
      return this.$store.state.shopNowNavigation;
    },
  }),
  watch: {
    totals() {
      if (!this.successfulSubmit) {
        this.checkCreditType();
      }
    }
  },
  async created() {
    this.$store.commit('firstMile/setOptions', []);
    this.checkExchangeType();
    this.checkCreditType();
    this.checkReturnType();
    if (!this.$store.state.fees.loading) {
      await this.$store.dispatch('fees/getFees', this.order);
    }

    if (this.hasFraudToolsSetting && !this.isWarrantyReturn) {
      this.checkFraudLoading = true;
      await this.$store.dispatch('check');
      this.checkFraudLoading = false;
    }

    this.$store.dispatch('firstMile/getEligibility');
  },
  mounted() {
    this.loadSignifydScript();
  },
  methods: {
    updatePayment(token) {
      this.token = token;

      if (!this.hasExchangeOrderCheckout) {
        this.$store.commit('return/setStripeToken', token);
      } else {
        this.$store.commit('return/setShopifyPaymentsToken', token);
      }
    },

    update3DS(stripe, elements, intentId) {
      this.stripe = stripe;
      this.elements = elements;
      this.intentId = intentId;

      this.$store.commit('return/setStripePaymentIntentId', intentId);
    },
    updateCredit(val) {
      this.$store.dispatch('updateOrder', {
        ...this.order,
        ...val
      });

      this.$store.commit('return/setStoreCreditType', val.storeCreditType);
      this.$store.commit('return/setCreditType', val.creditType);

      if (this.hasInstantRefundsFF && val.creditType === returnCreditTypes.REFUND) {
        this.checkReturnType();
        return;
      }

      this.$store.commit('return/setReturnType', null);
      this.$store.dispatch('fees/getFees', this.order);
    },
    updateReturn(val) {
      this.$store.commit('return/setReturnType', val.returnType);
    },
    updateExchange(val) {
      this.$store.dispatch('updateOrder', {
        ...this.order,
        ...val
      });
      this.$store.commit('return/setExchangeType', val.exchangeType);

      if (this.hasStripe3dsFF) {
        this.intentId = null;
        this.stripe = null;
        this.elements = null;

        this.$store.commit('return/setStripePaymentIntentId', null);
      }

      // Dump token if switching to regular exchange. The Stripe element does not keep the state anyway
      // and we need to be careful that we don't accidentally submit a saved token when the instant value is not selected
      if (val.exchangeType === returnTypes.REGULAR) {
        this.token = null;

        this.$store.commit('return/setStripeToken', null);
      }
    },
    async toPreviousPage() {
      if (this.hasCart) {
        this.$trackEvent('continue shopping');
      }
      this.$store.commit('setShopNowNavigation', false);

      if (this.$store.state.isEmbeddedPortal && this.onStoreEnabled) {
        await navigateToOnstore('review');
      }
    },
    async submit() {
      // If in edit mode, or if submit is disabled, we don't want an accidental return submission
      if (this.$store.state.edits.active || this.submitDisabled) {
        return;
      }
      this.submitting = true;

      try {
        this.$trackEvent('return submitted', {
          return_total_value: getCurrencyAmount(this.receipt.total, this.$settings.currency),
          return_upsell_total: getCurrencyAmount(this.receipt.newItemTotal, this.$settings.currency),
        });

        const returnItems = this.return.lineItems.map((item) => {
          const orderItem = this.order.line_items[item.id];

          return {
            item_id: orderItem.product_id,
            item_name: orderItem.title,
            item_variant: orderItem.variant_title,
            price: getCurrencyAmount(orderItem.price, this.$settings.currency),
          };
        });

        trackRefund({
          currency: this.receipt.currency,
          value: getCurrencyAmount(this.receipt.total, this.$settings.currency),
          transaction_id: this.order.name,
          shipping: getCurrencyAmount(this.receipt.shippingTotal, this.$settings.currency),
        }, returnItems);

      } catch (ex) {
        logError('GA Error - Submit Return', ex);
      }

      try {
        if (this.intentId) {
          const { error } = await this.stripe.confirmPayment({
            elements: this.elements,
            redirect: 'if_required'
          });
          if (error !== undefined) {
            throw new Error(error.message);
          }
        }

        await this.$store.dispatch('totals/get');
        let response = null;

        if (this.inShopLaterFlow) {
          response = await Return.update(
            this.return.key,
            this.return.shopLater.uuid,
            {
              ...this.$store.state.return,
              net: this.$store.state.totals.totals.computed.net,
              stripeToken: {
                id: this.token?.id,
              },
            });

          const { returnKey, returnOutcome, error, shopLaterOffer: { totalCreditOffer, bonus, fee } } = response;

          if (error) {
            throw new Error(error);
          }

          track('shopLaterOfferAccepted', {
            returnKey,
            returnOutcome,
            totalCreditOffer,
            bonus,
            fee
          });

        } else {
          response = await Return.submit({
            ...this.$store.state.return,
            orderId: this.order.id,
            returnPolicy: this.order.return_policy,
            returnMethod: this.order.returnMethod,
            carrierChoice: this.order.carrierChoice,
            signifydSessionId: this.signifydSessionId,
            source: this.source,
            isInStoreReturn: this.order.returnMethod?.isInStoreReturn,
            fraudScoreCheckedAtTimestamp: this.$store.state.fraudScoreCheckedAtTimestamp,
          });

          const { returnKey, returnOutcome, errors } = response;

          // Failed to generate return gives us a 200 with an errors array
          if (errors) {
            throw new Error(errors);
          }

          track('returnSubmitted', {
            ...this.analytics,
            returnKey,
            returnOutcome,
            isSplitReturn: this.$store.getters['return/isSplitReturn'],
          });
        }

        const { returnKey } = response;

        if (this.order.hasDisabledItems) {
          setStore('showNewReturnButton', returnKey);
        }

        await this.$router.push({
          name: 'StatusPage',
          params: {
            hash: returnKey
          }
        }).then(() => {
          this.$store.commit('updateOrder', 0);
          this.$store.commit('return/clear');
          this.$store.commit('updateData', {
            name: 'cartToken',
            data: null,
            save: true
          });
        });
      } catch (error) {
        logError(error);

        let message = error;
        if (error?.response?.status === 422) {
          message = this.$content.pageReview.validationErrorMessage;
        }

        if (error?.response?.status === 500 || error?.response?.status === 410) {
          message = error.response.data.error;
        }

        this.$store.commit('setError', {
          copy: message,
        });
        this.submitting = false;
      }
    },
    checkExchangeType() {
      const instantExchange = this.instantExchangeRequired && this.totals.computed.remainingCredit <= 0 ||
        this.instantExchangeRequired && this.receipt.newItems && Object.keys(this.receipt.newItems).length > 0;

      if (instantExchange && !this.hasReviewLineItem) {
        this.updateExchange({
          exchangeType: returnTypes.INSTANT
        });
      }
    },
    checkCreditType() {
      if (this.totals?.computed?.remainingCredit > 0) {
        let { refund, gift } = this.available;

        if (this.$store.getters.wasCashPurchased) {
          refund = false;
        }

        // check if all credit options are disabled before selecting
        if (!refund && !gift) {
          return;
        }

        if (this.return.storeCreditType !== storeCreditTypes.GIFTCARD) {
          this.updateCredit({ creditType: this.order.creditType, storeCreditType: storeCreditTypes.GIFTCARD });
        }

        // if refund isn't available, but the order credit type is refund, switch it.
        if (!refund && (!this.order.creditType || this.order.creditType === returnCreditTypes.REFUND)) {
          this.updateCredit({ creditType: returnCreditTypes.GIFT });
        }

        // same as above, switch credit type if non-available type is selected.
        if (!gift && (!this.order.creditType || this.order.creditType === returnCreditTypes.GIFT)) {
          this.updateCredit({ creditType: returnCreditTypes.REFUND });
          this.showRefundOnly = true;
        }
      }
    },
    checkReturnType() {
      if (
        this.hasInstantRefundsFF &&
        this.instantReturnRequired &&
        this.instantReturnEnabled &&
        !this.hasReviewLineItem
      ) {
        this.updateReturn({
          returnType: returnTypes.INSTANT
        });
      }
    },
    displayTitle(lineItem) {
      return replacePlaceholderUsingObject(this.$content.global.productTitle, lineItem);
    },
    onStripeSubmit() {
      if (!this.submitDisabled) {
        this.submit();
      }
    },
    showInstantExchangeExplanation() {
      this.showMoreInstantExchange = true;
    },
    showInstantReturnExplanation() {
      this.showMoreInstantReturn = true;
    },
    loadSignifydScript() {
      const signifydDeviceIDScript = document.createElement('script');
      signifydDeviceIDScript.setAttribute('defer', '');
      signifydDeviceIDScript.setAttribute('type', 'text/javascript');
      signifydDeviceIDScript.setAttribute('id', 'sig-api');
      signifydDeviceIDScript.setAttribute('src', 'https://cdn-scripts.signifyd.com/api/script-tag.js');
      this.signifydSessionId = uuid();
      signifydDeviceIDScript.setAttribute('data-order-session-id', this.signifydSessionId);
      document.head.appendChild(signifydDeviceIDScript);
    },
    handleAddressUpdate() {
      this.$store.dispatch('fees/getFees', this.order);
    },
  }
};
</script>

<style lang="scss" scoped>
$block: '.review-page';

#{$block} {
  background: white;

  &__container {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: var(--spacing-600) var(--spacing-900);
    max-width: calc(var(--max-page-width) + (var(--spacing-900) * 2));
    display: flex;
    flex-direction: column;
    color: var(--grey-800);
    margin: 0 auto;
  }

  &__heading {
    margin-bottom: var(--spacing-600);
  }

  &__main {
    * + * {
      margin-top: var(--spacing-400);
    }
  }

  &__support {
    margin-top: var(--spacing-600);

    :deep(.support-section__text) {
      color: #000;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__back-button {
    margin-top: var(--spacing-300);
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    &__container {
      padding: var(--spacing-200) var(--spacing-300);
    }
  }
}

@media screen and (max-width: $break-tiny) {
  #{$block} {
    &__container {
      padding: var(--spacing-200) 0;
    }

    &__heading {
      margin: var(--spacing-500) var(--spacing-400);
    }

    &__items,
    &__customer {
      border-radius: 0;
      border-right: none;
      border-left: none;
      padding: var(--spacing-500) var(--spacing-400);
      margin-top: var(--spacing-300);
    }

    &__summary,
    &__actions,
    &__support {
      padding: var(--spacing-500) var(--spacing-400);
      padding-bottom: 0;
      margin-top: 0;
      margin-left: 0;
    }
  }
}
</style>
