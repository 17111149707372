import { Factory, Model } from 'miragejs';
import { returns } from '../mocks/returns';

const models = () => {
  return {
    outcome: Model,
  };
};

const factories = () => {
  return {
    outcome: Factory.extend({
      'id': 4,
      'return_key': '38e845b4-b66f-48b8-a754-39a519ebe6cb',
      'order_id': 4,
      'shop_id': 317,
      'order_name': '#1009',
      'state': 'open',
      'outcome': 'default',
      'keep_items': false,
      'exchanges': [],
      'is_in_store_return': false,
      'shopLater': {
        'bonus': 1500,
        'createdAt': '2022-10-06T15:39:21.000000Z',
        'emailSentAt': null,
        'exchangesEnabled': 1,
        'giftCardEnabled': 1,
        'id': 1,
        'returnId': 1,
        'state': 'active',
        'totalCreditOffer': 25579,
        'updatedAt': '2022-10-06T15:39:21.000000Z',
        'uuid': '0bb7215e-8838-441f-a7c8-3680b018d68b'
      },
      'line_items': [
        {
          'id': 4,
          'title': 'San Diego Padres Cap',
          'sku': 'boston-hat-3',
          'price': 2999,
          'currency': 'USD',
          'variant_title': 'large',
          'image': 'https://cdn.shopify.com/s/files/1/0315/3634/9323/products/sd.jpg?v=1581513064',
          'outcome': 'default'
        },
        {
          'id': 5,
          'title': 'San Diego Padres Cap',
          'sku': 'boston-hat-3',
          'price': 2999,
          'currency': 'USD',
          'variant_title': 'large',
          'image': 'https://cdn.shopify.com/s/files/1/0315/3634/9323/products/sd.jpg?v=1581513064',
          'outcome': 'keep'
        }
      ],
      'policy': {
        'customizations': [
          {
            'lang': 'en',
            'content': {
              'pageConfirmation': {
                'instructions': [
                  'Securely pack items. If available, please use original packaging.',
                  'Attach your return label to the package.'
                ]
              }
            }
          }
        ]
      },
      'has_gift_card': false,
    })
  };
};

const seed = (context) => {
  returns.forEach((item) => {
    const {
      id,
      return_key,
      return_by,
      order_id,
      shop_id,
      order_name,
      state,
      outcome,
      keep_items,
      line_items,
      removed_line_items,
      exchanges,
      policy,
      instant_exchange_return_by,
      is_in_store_return,
      has_gift_card,
    } = item;
    context.create('outcome', {
      id,
      return_key,
      return_by,
      order_id,
      shop_id,
      order_name,
      state,
      outcome,
      keep_items,
      line_items,
      removed_line_items,
      exchanges,
      policy,
      instant_exchange_return_by,
      is_in_store_return,
      has_gift_card,
    });
  });
};

const routes = (context) => {
  context.get('/:shopId/return/:returnKey/outcomes', (schema, request) => {
    const return_key = request.params.returnKey;
    return findOrFail(schema, return_key);
  });
};

const findOrFail = (schema, returnKey) => {
  const item = schema.outcomes.findBy({ return_key: returnKey });
  if (item) {
    return item.attrs;
  }

  return new Response(
    404,
    { 'Content-Type': 'application/json' },
    {
      errors: [{
        title: 'return not found',
        description: 'return could not be found'
      }]
    }
  );
};

export default {
  factories,
  routes,
  models,
  seed
};
